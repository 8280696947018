<template>
  <div class="justify-center image-container">
    <div v-for="(page, index) in pageInfo.images"
         :key="index"
         class="py-0"
    >
      <div>
        <div v-if="!page.isError"
             :style="{position: 'relative'}"
        >
          <Editor

            :ref="`editor${index}`"
            :canvas-width="600+index"
            :canvas-height="600+index"
            :width="600+index"
            :height="600+index"
            :editor-number="index"
            @image-loaded="imageLoaded()"
          />

          <!-- Fullscreen Toggle Button -->
          <v-btn
            color="transparent"
            class="white--text fullscreen-btn"
            fab
            small
            @click="toggleFullscreen(page.image)"
          >
            <v-icon large>
              fullscreen
            </v-icon>
          </v-btn>

          <full-screen-project-viewer
            v-if="isFullscreen && currentImage === page.image"
            :image-src="currentImage"
            :is-fullscreen.sync="isFullscreen"
            @update:is-fullscreen="exitFullscreen"
          />

          <div
            v-for="detail in details"
            :key="detail.id"
            :style="{
              position: 'absolute',
              top: `${detail.y / 2048 * 600 - 18}px`,
              left: `${detail.x / 2048 * 600 - 18}px`,
              width: '36px',
              height: '36px',
              cursor: 'pointer',
              zIndex: 1000
            }"
            @mouseenter="handleHoverDetail(detail)"
            @mouseleave="clearSelectedDetail()"
          />
          <svg
            v-if="highlightCoordinates.x != null && highlightCoordinates.y != null"
            :width="36"
            :height="36"
            :style="{
              position: 'absolute',
              // eslint-disable-next-line max-len
              top: `${highlightCoordinates.y / 2048 * 600 - 18}px`,
              // eslint-disable-next-line max-len
              left: `${highlightCoordinates.x / 2048 * 600 - 18}px`}"
          >
            <rect
              fill="transparent"
              stroke="rgba(255, 0, 0, 0.85)"
              stroke-width="4"

              :width="36"
              :height="36"
            />
          </svg>
        </div>
        <div v-else
             :style="{position: 'relative'}"
        >
          <div style="width: 300px; padding: 10px; margin: auto; text-align: center; color: red;">
            Error analyzing page {{ page.pageId }}, status {{ page.status }}.
          </div>
        </div>
      </div>
      <div class="image-container__image-info"
           :style="{ width: canvasWidth + 'px' }"
      >
        <div class="d-flex align-center">
          <span class="image-container__image-info--font">
            Capture Id: {{ page.captureId }} Frame: {{ page.frame + 1 }}
          </span>
          <v-spacer />
          <v-col cols="3" />
          <v-col cols="1">
            <v-menu
              close-on-click
              offset-y
              offset-x
            >
              <template #activator="{on}">
                <v-btn
                  color="secondary"
                  icon
                  tile
                  :ripple="false"
                  v-on="on"
                >
                  <v-icon>
                    more_horiz
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="undo(index)"
                >
                  <v-list-item-title>
                    {{ $t('imageStudio.undo') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="clear(index)"
                >
                  <v-list-item-title>
                    {{ $t('imageStudio.clear') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="removeFromPage(index)"
                >
                  <v-list-item-title>
                    {{ $t('imageStudio.removePage') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FullScreenProjectViewer from './FullScreenProjectViewer.vue';
import Editor from './Editor.vue';
// import config from '../../js/config';
import imagesService from '../../js/services/imagesService';
import imageStudioService from '../../js/services/imageStudioService';

export default {
  name:       'ImageEditorPage',
  components: {
    Editor, FullScreenProjectViewer,
  },
  props: {
    pageInfo: {
      type: Object,
      default() {
        return {
          images:   [],
          commands: [],
        };
      },
    },
    // zero based index
    pageIndex: {
      type:    Number,
      default: -1,
    },
    highlightCoordinates: {
      type:    Object,
      default: null,
    },
    details: {
      type:    Array,
      default: () => [],
    },
    handleHoverDetail: {
      type:    Function,
      default: () => {},
    },
    clearSelectedDetail: {
      type:    Function,
      default: () => {},
    },
  },
  data() {
    return {
      imageLoadedCount: 0,
      isFullscreen:     false,
      currentImage:     null,
    };
  },
  computed: {
    canvasWidth() {
      return this.computeCanvasDimensions();
    },
    canvasHeight() {
      return this.computeCanvasDimensions();
    },
  },
  watch: {
    pageInfo: {
      deep: true,
      handler() {
        this.loadContent();
      },
    },
  },
  mounted() {
    this.loadContent();
  },
  beforeDestroy() {
    this.saveCommands();
    this.$context.history.clear();
  },
  methods: {
    async loadFrame(index, url) {
      if (url && url !== '') {
        const img = await imagesService.loadImage(url);
        const blob = await fetch(img).then(res => res.blob());

        const e = { target: { files: [blob] } };
        const editor = this.$refs[`editor${index}`][0];

        editor.setImage(e);
      }
    },
    computeCanvasDimensions() {
      // eslint-disable-next-line
      console.log(`Size ${this.$vuetify.breakpoint.name} HxW ${this.$vuetify.breakpoint.width}x${this.$vuetify.breakpoint.height}`);
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 225;

        case 'sm':
          return 325;

        case 'md':
          return 400;

        case 'lg':
          return 600;

        case 'xl':
          return 600;

        default:
          return 425; // Default size if none of the above
      }
    },
    loadContent() {
      this.imageLoadedCount = 0;
      this.pageInfo.images.forEach((page, index) => {
        // if (page.image.startsWith('api/')) {
        //   page.image = `${config.apiUrl}${page.image}`;
        // }

        if (!page.isError) {
          this.loadFrame(index, page.image);
        }
      });
    },
    saveCommands() {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.endTextDraw();
      }

      const cmds = this.getCommands();

      this.$emit('commands-updated', this.pageIndex, cmds);
    },
    imageLoaded() {
      // console.log(`imgLoaded ${this.imageLoadedCount} ${this.pageInfo.images.length}`);
      this.imageLoadedCount++;

      if (this.imageLoadedCount === this.pageInfo.images.length) {
        // console.log(`Commands2 ${this.pageInfo.commands}`);
        this.pageInfo.commands.forEach(cmd => {
          // console.log(`iamgeLoaded cmd.cid=${cmd.commandName} imageLoadedCount=${this.imageLoadedCount}`);
          if (this.$refs[`editor${cmd.cid}`]) {
            const editor = this.$refs[`editor${cmd.cid}`][0];

            editor.addCommand(cmd);
          }
        });

        this.$emit('page-loaded');
      }
    },
    enableLine(params) {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.enableFreeDraw(params);
      }
    },
    enableRectangle(params) {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.enableRect(params);
      }
    },
    enableEllipse(params) {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.enableEllipse(params);
      }
    },
    enableText(params) {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.enableText(params);
      }
    },
    enableArrow(params) {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.enableArrow(params);
      }
    },
    enableCrop() {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.enableCrop();
      }
    },
    undo(index) {
      if (index) {
        let found = 0;
        const cmds = [];

        while (this.$context.history.length > 0) {
          const cmd = this.$context.history.pop();

          if (index === cmd.cid && !found) {
            found = 1;
            cmd.undo();
          } else {
            cmds.push(cmd);
          }
        }
        cmds.reverse();
        cmds.forEach(c => {
          this.$context.history.push(c);
        });
      } else if (this.$context.history.length) {
        this.$context.history.pop().undo();
      }

      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.refresh();
      }
    },
    clear(index) {
      const cmds = [];

      while (this.$context.history.length > 0) {
        const cmd = this.$context.history.pop();

        if (cmd.cid === index) {
          cmd.undo();
        } else {
          cmds.push(cmd);
        }
      }
      cmds.reverse();
      cmds.forEach(c => {
        this.$context.history.push(c);
      });

      const editor = this.$refs[`editor${index}`][0];

      editor.refresh();
    },
    removeCommands(imageIndex) {
      // console.log(`Removing commands from page ${imageIndex}`);

      const cmds = [];

      while (this.$context.history.length > 0) {
        const cmd = this.$context.history.pop();

        if (imageIndex !== cmd.cid) {
          cmds.push(cmd);
        }

        if (cmd.cid > imageIndex) {
          // console.log(`Removing command decrementing ${cmd.cid}`);
          cmd.cid--;
        }
      }

      cmds.reverse();
      while (cmds.length > 0) {
        this.$context.history.push(cmds.pop());
      }
    },
    getCommands() {
      const exportInfo = [];
      const cmds = [];

      // console.log(`getCommands ${this.$context.history.length}`);
      while (this.$context.history.length > 0) {
        const cmd = this.$context.history.pop();

        exportInfo.push(cmd.export());
        cmds.push(cmd);
      }
      cmds.reverse();

      while (cmds.length > 0) {
        this.$context.history.push(cmds.pop());
      }

      return exportInfo.reverse();
    },
    toggleFullscreen(image) {
      this.currentImage = image;
      this.isFullscreen = !this.isFullscreen;

      if (this.isFullscreen) {
        this.enterFullscreen();
      } else {
        this.exitFullscreen();
      }
    },
    enterFullscreen() {
      const element = this.$el;

      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    },

    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      this.isFullscreen = false;
    },
    async save(projectId, pageNumber) {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        const backgroundImage = { ...editor.canvas.backgroundImage };

        if (!backgroundImage) {
          // eslint-disable-next-line
          continue;
        }

        const newScaleX = backgroundImage.scaleX;
        const newScaleY = backgroundImage.scaleY;

        if (!newScaleX || !newScaleY) {
          // eslint-disable-next-line
          continue;
        }

        const bgParams = {
          scaleX: editor.canvas.width / (newScaleX
          * backgroundImage.width),
          scaleY: editor.canvas.height / (newScaleY
          * backgroundImage.height),
          translateX: backgroundImage.left
          / newScaleX,
          translateY: backgroundImage.top
          / newScaleY,
        };

        const imgData = editor.saveImage();
        const cmds = [];
        const currentCmds = [];

        while (this.$context.history.length > 0) {
          const cmd = this.$context.history.pop();

          if (cmd.cid === i) {
            cmds.push(cmd.export());
          }

          currentCmds.push(cmd);
        }
        cmds.reverse();
        currentCmds.reverse();

        while (currentCmds.length > 0) {
          this.$context.history.push(currentCmds.pop());
        }

        const data = {
          captureId:               this.pageInfo.images[i].captureId,
          cameraCaptureSettingsId: this.pageInfo.images[i].cameraCaptureSettingsId,
          angle:                   this.pageInfo.images[i].angle,
          frame:                   this.pageInfo.images[i].frame,
          commands:                JSON.stringify(cmds),
          cropInfo:                bgParams,
          pageNumber,
        };

        data.data = imgData;

        // TODO We disable this warning because it must be done in a loop because the
        // API only handles it one at a time. We can do better and allow the API to do one
        // upload with all the images. Future work.
        /* eslint-disable no-await-in-loop */
        await imageStudioService.uploadProjectImage(projectId, data);
      }
    },
    removeFromPage(index) {
      this.$emit('remove-page', index);
    },
  },

};
</script>
<style lang="scss" scoped>
.image-container {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  &__image-info {
    padding-top: 20px;
    display: contents;
  }
}
.image-container > div:only-child {
  grid-column: 1 / -1;
}
@media (max-width: 768px) {
  .image-container {
    grid-template-columns: 1fr;
  }
}
.fullscreen-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
}
</style>
