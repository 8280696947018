export default {
  /**
   * Whether the application is running in the Cloud mode.
   */
  isInCloudMode() {
    return JSON.parse(process.env.VUE_APP_CLOUD);
  },

  isCognitoEnabled() {
    return process.env.VUE_APP_COGNITO_ENABLED === `true`;
  },

  getCognitoClientId() {
    return process.env.VUE_APP_COGNITO_CLIENT_ID;
  },

  getCognitoUserPoolId() {
    return process.env.VUE_APP_COGNITO_USER_POOL_ID;
  },

  getCognitoRegion() {
    return process.env.VUE_APP_COGNITO_REGION;
  },

  getAppHostname() {
    const envValue = process.env.VUE_APP_HOSTNAME;

    if (envValue) {
      return envValue;
    }

    return window.location.hostname;
  },

  getLogoutOrigin() {
    const envValue = process.env.VUE_APP_COGNITO_LOGOUT_ORIGIN;

    if (envValue) {
      return envValue;
    }

    return `https://login.${this.getAppHostname()}`;
  },
};
